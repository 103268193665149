exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-referenzen-js": () => import("./../../../src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-uber-uns-js": () => import("./../../../src/pages/uber-uns.js" /* webpackChunkName: "component---src-pages-uber-uns-js" */),
  "component---src-pages-usability-js": () => import("./../../../src/pages/usability.js" /* webpackChunkName: "component---src-pages-usability-js" */),
  "component---src-templates-case-study-case-study-js": () => import("./../../../src/templates/CaseStudy/case-study.js" /* webpackChunkName: "component---src-templates-case-study-case-study-js" */)
}

